// window._ = require('lodash');
// import Popper from 'popper.js/dist/umd/popper.js';

try {
    window.$ = window.jQuery = require('jquery');
    require('jquery-validation');
  	// require('chosen-jquery-browserify');

  	// window.Popper = Popper;
  	// require('bootstrap');
  	require('bootstrap/js/src/carousel.js')
  	require('bootstrap/js/src/tooltip.js')

  	require('slick-carousel');

  	require('./scripts');
} catch (e) {
}

jQuery.noConflict();
