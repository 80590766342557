/**
 * @package     Joomla.Site
 * @subpackage  B4 theme
 *
 * @copyright   Copyright (C) 2017 UAB IT Solutions, Inc. All rights reserved.
 * @license     NON-FREE. Contact info@itsolutions.lt for licensing details
 */

(function($){

	$(document).ready(function(){

		if ($(window).width() > 974){
			var max_div_height = 0,
			div_height = 0;
			$(".services_h_mod .mod-intro-text").each(function(){
				div_height = $(this).height();
				if(div_height > max_div_height)
					{
					max_div_height = div_height;
					}
			});
			$('.services_h_mod .mod-intro-text').height(max_div_height);

			var max_div_height = 0,
			div_height = 0;
			$(".services_h_mod .mod-full-text").each(function(){
				div_height = $(this).height();
				if(div_height > max_div_height)
					{
					max_div_height = div_height;
					}
			});
			$('.services_h_mod .mod-full-text').height(max_div_height);
		}


		//landing page service N line blocks

		var black_bkgr_left = 0;
		var info_block_left = 0;
		var black_bkgr_right = 0;
		var info_block  = 0;
		var add_right = 0;
		$(".services_n_mod .content_block").each(function(){

			black_bkgr_left 	= $(this).find('.black_bkgr_left').height();
			info_block_left 	= $(this).find('.info-block-left').height();
			black_bkgr_right 	= $(this).find('.black_bkgr_right').height();
			info_block 			=  $(this).find('.info-block').height();

			if ((black_bkgr_left + info_block_left) > (black_bkgr_right + info_block) ){
				add_right = black_bkgr_left + info_block_left -  black_bkgr_right;
				$(this).find('.info-block').height(add_right);
			}
			else if ((black_bkgr_left + info_block_left) < (black_bkgr_right + info_block) ){
				add_left = black_bkgr_right + info_block -  black_bkgr_left;
				$(this).find('.info-block-left').height(add_left);
			}
		});


		// vanila js accordion
		(function () {
			var accordions, i;

			// Make sure the browser supports what we are about to do.
			if (!document.querySelectorAll || !document.body.classList) return;

			// Using a function helps isolate each accordion from the others
			function makeAccordion(accordion) {
				var targets, currentTarget, i, vasExpanded = false;

				targets = accordion.querySelectorAll('.js-accordion > * > .accordion_header');
				for(i = 0; i < targets.length; i++) {
					targets[i].addEventListener('click', function () {

						currentTarget = this.parentNode;
						vasExpanded = false;
						if (currentTarget.classList.contains('expanded')){
							vasExpanded = true;
						}
						var divs = document.querySelectorAll('.expanded');
						for (var i = 0; i < divs.length; i++) {
						    divs[i].classList.remove('expanded');
						}
						if (!vasExpanded){
							currentTarget.classList.add('expanded');
						}
					}, false);
				}
				accordion.classList.add('js');
			}

			// Find all the accordions to enable
			accordions = document.querySelectorAll('.js-accordion');

				// Array functions don't apply well to NodeLists
			for(i = 0; i < accordions.length; i++) {
				makeAccordion(accordions[i]);
			}

		})();
		//end  accordion

		var max_div_height1 = 0,
			max_div_height2 = 0,
			div_height1 = 0,
			div_height2 = 0;
		$(".article-info").each(function(){
			div_height1 = $(this).find('.card-text').height();
			div_height2 = $(this).find('.card-title').height();

			if(div_height1 > max_div_height1)
				{
				max_div_height1 = div_height1;
				}
			if(div_height2 > max_div_height2)
				{
				max_div_height2 = div_height2;
				}
		});
		$('.article-info .card-text').height(max_div_height1);
		$('.article-info .card-title').height(max_div_height2);

		// start up tooltips
		$('[data-toggle="tooltip"]').tooltip()

		// define var's
		var sections_timer 	= null;
		var window_height 	= $(window).height();

		// fix section heights
		//setTimeout(function(){
			$('section.fsh').each(function(){
				var section = $(this),
					section_height = section.outerHeight();


				if (section_height < window_height) {
					section.height(window_height);
				}
			});
		//}, 1500);

		var showcase = $('#showcase-row');
		if(typeof showcase === 'undefined' || showcase.length == 0) {
			$('body').addClass('spacer-top');
		}

		// remove messages container
		var msgcontainer = $('#message-container');

		if (msgcontainer.length > 0) {
                        setTimeout(function(){
				msgcontainer.remove();
			}, 10000);
                }

		$('#attachments_table').on('change', 'input[type="file"]', function(){
			var ff = $(this).parent().get(0);
			var table = $(ff).parent();

			var fullPath = $(this).val();
			var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
			var filename = fullPath.substring(startIndex);
			if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
			    filename = filename.substring(1);
			}

			table.find('.filename').html(filename);
		});

		$('.scroll-page').on('click', function(){

			var parent 		= $(this).parent();
			var parent_top_position	= parent.offset().top;
			var parent_height	= parent.outerHeight();

			var scroll = window_height;

			if (window_height - (parent_top_position + parent_height) <= 0) {
                                scroll = parent_top_position + parent_height
                        }

			$('html, body').animate({scrollTop: scroll}, 600);
		});

		// fix readio buttos
		$('.form-control.radio').each(function(){
			var radios 		= $(this).find('input[type="radio"]');
			var active_radio 	= null;
			radios.each(function(){
				var r = $(this).attr('checked');
				if (typeof r !== typeof undefined && r !== false) {
					active_radio = $(this);
				}
			});

			if(active_radio !== null) {
				var id 		= active_radio.attr('id');
				var label 	= $(this).find('label[for="'+id+'"]');

				if (typeof label !== typeof undefined && label !== false) {
                                        label.addClass('active');
                                }
			}
		});

		$('.form-control.radio').on('click', 'label', function(){
			var wrapper = $(this).parent();
			wrapper.find('label.active').removeClass('active');
			$(this).addClass('active');
		});

		// start video play in list
		$('.items-block .item.video').on('click', function(){
			var play = $(this);
			play.find('picture').hide();
			play.find('.card-label').hide();
			play.find('.card-img-overlay').hide();
			var player_wrapper = play.find('.embed-responsive');
			var player = player_wrapper.find('iframe');
			player_wrapper.show();
			player[0].src += '?autoplay=1';
		});

		fixSections($(window));
		msieversion();
	});

	var $window = $(window);

	// fix sections on scroll
	$window.on('scroll', function(){
		fixSections($(this));

	});

	$window.on('load', function(){


		if ($("#read_more_lines_num").val() > 0) {

			var line_num = $("#read_more_lines_num").val();
			var moretext = '<i class="pe-7s-angle-down" ></i';
			var lesstext = '<i class="pe-7s-angle-up" ></i';
			var less = 1;

			var fadeout_height = $(".fadeout_height").height();
			var divheight = $(".category-description").height();
			var lineheight = $(".category-description").css('line-height').replace("px","");
			var lines = Math.round(divheight/parseInt(lineheight));

			if (lines > 6) {
				$(".cat_desc_read_more").css("display", "block");
				$(".cat_desc_read_more").html(moretext);
				$(".fadeout").css("display", "block");
				var line_5_height = lineheight * line_num;
				$(".category-description").height(line_5_height+5);
				}

				$(".cat_desc_read_more").on("click", function(){

				if($(this).hasClass("less")) {
					$(".category-description").animate({height: line_5_height});
					$(".cat_desc_read_more").html(moretext);
					//$(".fadeout").css("display", "block");
					$(this).removeClass("less");
				}
				else  {
					$(".category-description").animate({height: (divheight+20)});
					$(".cat_desc_read_more").html(lesstext);
					 setTimeout( function(){
						//$(".fadeout").css("display", "none");
					 }, 200);
					$(this).addClass("less");
				}
			});
		}


		var $body = $('body');
		var $window = $(this);

		if($body.height() < $window.height()) {
			var content_height = $('#content-wrapper').height();
			$('#content-wrapper').height(content_height + ($window.height() -$body.height() ));
		}

		if($(window).width() > 750){	// like X > 768
			var max_div_height = 0,
				div_height = 0;
			$(".card-body .card-text").each(function(){
			div_height = $(this).outerHeight();
			if(div_height > max_div_height)
				{
				   max_div_height = div_height;
				}
			});
			$('.card-body .card-text').height(max_div_height);
		}

		var max_div_height = 0,
			div_height = 0;
		$(".blog-items .card-body").each(function(){
		div_height = $(this).height();
		if(div_height > max_div_height)
			{
				max_div_height = div_height;
			}
		});
		$('.blog-items .card-body').height(max_div_height);

	});

	// fix sections
	function fixSections( window_ob ) {

		if ($('body.home_page').length){
			var slider_height = $('#showcase-row').height();
		}
		else{
			var slider_height = 100;
		}

		var window_offset 	= window_ob.scrollTop();

		if ($(window).width() > 750){
			if (window_offset > slider_height){
				$('#top-a-row').addClass('menu-background');
			}
			else{
				$('#top-a-row').removeClass('menu-background');
			}
		}

		var body		= $('body');

                $('.fixed-section', '#header-wrapper').each(function(){

			var $this = $(this);

			if (body.hasClass('loged')) {
				$this.addClass('nonzero-position');
                                $this.addClass('fix-to-top');
                        } else {
				var element_offest = $this.offset().top;

				if (body.hasClass('home_page')) {
					if (element_offest > 0) {
						$this.addClass('nonzero-position');
					} else {
						$this.removeClass('nonzero-position');
					}
				} else {
					$this.addClass('nonzero-position');
				}

				if (typeof $this.attr('top-pos') !== 'undefined') {
					var element_offest = $this.attr('top-pos');
				}

				var element_height = $this.outerHeight();

				if (window_offset >= element_offest && !$this.hasClass('fix-to-top')) {

					// add pseudo element to avoid content jump
					if (element_offest > 0/* || !body.hasClass('home_page')*/) {
						$this.before('<div class="fix-top-pseudo" style="height:'+element_height+'px"></div>');
					}

					$this.addClass('fix-to-top');
					$this.attr('top-pos', element_offest);

				} else if (window_offset < element_offest && $this.hasClass('fix-to-top')) {

					$this.removeClass('fix-to-top');
					if (element_offest > 0 || !body.hasClass('home_page')) {
						$this.prev('.fix-top-pseudo').remove();
					}

				}
			}
		});

        }

	function msieversion() {

		var ua 		= window.navigator.userAgent;
		var msie 	= ua.indexOf("MSIE ");
		var body 	= $('body');

		if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
		{
			body.addClass('msie');
		}

		return false;
	}
})(jQuery);
